import React from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { NavLink, Routes,Route } from "react-router-dom";
import { FaLinkedin } from 'react-icons/fa';


// import { CTA } from "../components";
import { experiences, skills } from "../constants";
import SkillApp from './SkillApp';

const AboutTwo = () => {
  return (
    <section className='max-container'>
      <h1 className='head-text'>
        Hello, I'm{" "}
        <span className='gradient_text font-semibold drop-shadow'>
          {" "}
          Estelo
        </span>{" "}
        {/* 👋 */}
      </h1>

      <div className='mt-5 flex flex-col gap-3 text-slate-500'>
        <p>
        Building on the skills I gained through the Google UX Certificate and currently taking more online course called "Master Figma" from figmatraining.com to expand my knownlwedge in UX design.
        I am eager to bring my expertise and creativity to my next opportunity, driving meaningful and impactful solutions.
        </p>
        <p>
        This portfolio was hard-coded from scratch using the React framework, crafted entirely with Visual Studio Code and managed through GitHub. It’s a testament to my hands-on skills, showcasing my ability to build dynamic projects while demonstrating my dedication to learning and growth.
          </p>
        {/* <NavLink to='/contact' className={({ isActive }) => isActive ? "text-blue-600" : "text-black"}>
          Contact ME!
        </NavLink> */}
         <a href="https://www.linkedin.com/in/estelo-abellanosa-83a335186/" target="_blank">Contact Me!</a>
      </div>

      <div className='py-10 flex flex-col'>
        <h3 className='subhead-text'>My Skills</h3>

        {/* <SkillApp /> */}
        
        {/* <div className='mt-16 flex flex-wrap gap-12'>
          {skills.map((skill) => (
            <div className='block-container w-20 h-20' key={skill.name}>
              <div className='btn-front rounded-xl flex justify-center items-center'>
                <img
                  src={skill.imageUrl}
                  alt={skill.name}
                  className='w-1/2 h-1/2 object-contain'
                />
              </div>
            </div>
          ))}
        </div> */}
      </div>

      <div className='py-16'>
        <h3 className='subhead-text'>Work Experience.</h3>
        <div className='mt-5 flex flex-col gap-3 text-slate-500'>
          <p>
          My diverse work experience has equipped me with adaptability, attention to detail, and a strong work ethic, having worked in roles ranging from quality control to inventory consolidation. However, my passion for creating impactful digital experiences led me to dedicate my free time to self-learning web development and UX design. Through this journey, I’ve built skills in front-end development and user-centered design, driven by a commitment to solving real user problems. I am eager to bring this unique blend of skills and experiences to my next opportunity.
          </p>
        </div>

        <div className='mt-12 flex'>
          <VerticalTimeline>
            {experiences.map((experience, index) => (
              <VerticalTimelineElement
                key={experience.company_name}
                date={experience.date}
                iconStyle={{ background: experience.iconBg }}
                icon={
                  <div className='flex justify-center items-center w-full h-full'>
                    {/* <img
                      src={experience.icon}
                      alt={experience.company_name}
                      className='w-[60%] h-[60%] object-contain'
                    /> */}
                  </div>
                }
                contentStyle={{
                  borderBottom: "8px",
                  borderStyle: "solid",
                  borderBottomColor: experience.iconBg,
                  boxShadow: "none",
                  paddingTop: "100px",
                }}
              >
                <div>
                  <h3 className='text-black text-xl font-poppins font-semibold'>
                    {experience.title}
                  </h3>
                  <p
                    className='text-black-500 font-medium text-base'
                    style={{ margin: 0 }}
                  >
                    {experience.company_name}
                  </p>
                </div>

                <ul className='my-5 list-disc ml-5 space-y-2'>
                  {experience.points.map((point, index) => (
                    <li
                      key={`experience-point-${index}`}
                      className='text-black-500/50 font-normal pl-1 text-sm'
                    >
                      {point}
                    </li>
                  ))}
                </ul>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      </div>

      <hr className='border-slate-200' />

      {/* <CTA /> */}
    </section>
  )
}

export default AboutTwo