import React, { Fragment } from 'react';
import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";
import {  Route, Routes, createBrowserRouter } from "react-router-dom";

// import Nav from './components/Nav';
import Nav from './components/Navbar';
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";
import Mobile from "./components/Mobile";
import PortfolioCard from "./components/PortfolioCard";
import Contact from "./components/Contact";
import ContactForm from './components/ConformForm';
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import NavTwo from "./components/NavTwo";
import CaseStudy from './components/CaseStudy';

import HomeTwo  from './tabs/Hometwo';
import AboutTwo  from './tabs/AboutTwo';
import Projects  from './tabs/Projects';
import Msg  from './tabs/Msg';
import Surbing from './components/CaseStudy/Threetry/Surbing';
import Threetry from './components/CaseStudy/Threetry/Threetry';
import Proxy from './components/CaseStudy/Threetry/Proxy';
import Safeguard from './components/CaseStudy/Threetry/Safeguard';
import Parcel from './components/CaseStudy/Threetry/Parcel';


import '../src/styles/main.scss';

const App = () => {
  return (
    <Router>
    <React.Fragment>
      <Nav />
    
    <div className="App">
      <Routes>
        <Route path='/' element={<Projects />} />
          <Route
            path='/*'
            element={
              <>
                <Routes>
                  <Route exact path='/about' element={<AboutTwo />} />
                  <Route exact path='/casestudy' element={<CaseStudy />} />
                  <Route exact path='/contact' element={<Msg />} />
                  <Route exact path='/surbing' element={<Surbing />} />
                  <Route exact path='/threetry' element={<Threetry />} />
                  <Route exact path="/proxy" element={<Proxy />} />
                  <Route exact path="/safeguard" element={<Safeguard />} />
                  <Route exact path="/parcel" element={<Parcel />} />
                </Routes>
              </>
              
            }
            />
      </Routes>
      <Footer />
    </div>
      </React.Fragment>
      </Router>
  );
}

export default App;


// import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// import { Footer, Navbar } from "./components";
// import { About, Contact, Home, Projects, Msg } from "./pages";

// const App = () => {
//   return (
    // <main className='bg-slate-300/20'>
    //   <Router>
    //     <Navbar />
    //     <Routes>
    //       <Route path='/' element={<Home />} />
    //       <Route
    //         path='/*'
    //         element={
    //           <>
    //             <Routes>
    //               <Route path='/about' element={<About />} />
    //               <Route path='/projects' element={<Projects />} />
    //               <Route path='/contact' element={<Msg />} />
    //             </Routes>
    //             <Footer />
    //           </>
              
    //         }
    //       />
    //     </Routes>
    //   </Router>
    // </main>
//   );
// };

// export default App;