import React from 'react';
import Case from "./CaseStudy/Case";

const CaseStudy = () => {
  return (
    <section className='case-container'>
      <Case />
    </section>
  )
}

export default CaseStudy