// import { Card, CardContent } from "@/components/ui/card";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableHeader,
//   TableRow,
// } from "@/components/ui/table";
// import React from "react";

// export default function TaskMapping() {
//   // Define table data structure for better maintainability
//   const tableHeaders = ["Step 1", "Step 2", "Step 3", "Step 4"];

//   const tableRows = [
//     {
//       title: "Task",
//       description: "Lorem ipsum dolor sit ame",
//       steps: [
//         "Risus mollis dictum dolor, quam.",
//         "consectetur adipiscing elit",
//         "adipiscing a aliquet justo c",
//         "quam tincidunt. Nisl cursus",
//       ],
//     },
//     {
//       title: "Environment",
//       description: "",
//       steps: [
//         "Risus mollis dictum dolor, quam.",
//         "consectetur adipiscing elit",
//         "adipiscing a aliquet justo c",
//         "quam tincidunt. Nisl cursus",
//       ],
//     },
//     {
//       title: "Challenges",
//       description: "",
//       steps: [
//         "Risus mollis dictum dolor, quam.",
//         "consectetur adipiscing elit",
//         "adipiscing a aliquet justo c",
//         "quam tincidunt. Nisl cursus",
//       ],
//     },
//     {
//       title: "Emotions",
//       description: "",
//       steps: [
//         "consectetur adipiscing",
//         "adipiscing a aliquet justo c",
//         "quam tincidunt. Nisl cursus",
//         "Risus mollis dictum dolor",
//       ],
//     },
//     {
//       title: "Thoughts",
//       description: "",
//       steps: [
//         "Risus mollis dictum dolor, quam.",
//         "consectetur adipiscing elit",
//         "adipiscing a aliquet justo c",
//         "quam tincidunt. Nisl cursus",
//       ],
//     },
//     {
//       title: "Urgency Level",
//       description: "",
//       steps: [
//         "consectetur adipiscing",
//         "adipiscing a aliquet jus",
//         "quam tincidunt. Nisl cursus",
//         "Risus mollis dictum dolor",
//       ],
//     },
//     {
//       title: "Design Opportunity",
//       description: "",
//       steps: [
//         "Risus mollis dictum dolor, quam.",
//         "consectetur adipiscing elit",
//         "adipiscing a aliquet justo c",
//         "quam tincidunt. Nisl cursus",
//       ],
//     },
//   ];

//   return (
//     <div className="w-full min-h-screen bg-white p-6">
//       <h1 className="text-dark font-heading text-[48px] font-semibold tracking-[-1.44px] mb-12 mt-20 ml-[165px]">
//         Task Mapping
//       </h1>

//       <Card className="w-full max-w-[1188px] mx-auto mt-16 border-0 shadow-none">
//         <CardContent className="p-0">
//           <Table>
//             <TableHeader>
//               <TableRow className="border-b">
//                 <TableHead className="w-[180px]"></TableHead>
//                 {tableHeaders.map((header, index) => (
//                   <TableHead
//                     key={index}
//                     className="font-body-small text-dark text-[22px] font-medium leading-[173.1%]"
//                   >
//                     {header}
//                   </TableHead>
//                 ))}
//               </TableRow>
//             </TableHeader>
//             <TableBody>
//               {tableRows.map((row, rowIndex) => (
//                 <TableRow key={rowIndex} className="border-t">
//                   <TableCell className="font-body-small text-dark text-[22px] font-medium leading-[173.1%] align-top py-6">
//                     {row.title === "Design Opportunity" ? (
//                       <div className="w-[180px]">
//                         Design
//                         <br />
//                         Opportunity
//                       </div>
//                     ) : (
//                       row.title
//                     )}
//                     {row.title === "Task" && (
//                       <div className="font-body-extra-small text-dark text-[20px] font-normal leading-[148.1%] mt-2">
//                         {row.description}
//                       </div>
//                     )}
//                   </TableCell>
//                   {row.steps.map((step, stepIndex) => (
//                     <TableCell
//                       key={stepIndex}
//                       className="font-body-extra-small text-dark text-[20px] font-normal leading-[148.1%] align-top py-6"
//                     >
//                       {step}
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </CardContent>
//       </Card>
//     </div>
//   );
// }


import React from 'react'

const TaskMapping = () => {
  return (
    <div>TaskMapping</div>
  )
}

export default TaskMapping