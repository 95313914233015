import React from 'react'
import Hero from '../Hero'
import ProblemStatement from '../Problem'
import '../Styles/Threetry.scss'


const Threetry = () => {
  return (
    <div className='threetry-container'>
      <Hero />
      {/* <ProblemStatement /> */}
    </div>
  )
}

export default Threetry