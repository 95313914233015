import React from 'react'
import Hero from '../Hero'
import ProblemStatement from '../Problem'
import OurProcess from '../OurProcess'
import ObjectivesGoals from '../Goals'
import UserNeeds from '../UserNeeds'
import Features  from '../Features'
import BusinessChallenges from '../Buniness'
import ProductUsers from '../ProductUsers'
import Research from '../Research'
import CompetitorAnalysis from '../Competitor'
import UniqueFeatures from '../Unique'
import UserPersona from '../UserPersona'
import TaskMapping from '../TaskMapping'
import Sketches from '../Sketches'
import Screens from '../Screens'
import SurbingPNG from '../Styles/surbing.png'

import "../Styles/Surbing.scss"


const Surbing = ({}) => {
  return (
    <div className='surbing-container'>
        <Hero img={SurbingPNG}/>
        {/* <ProblemStatement />
        <ObjectivesGoals /> 
        <OurProcess />
        <BusinessChallenges />
        <ProductUsers /> 
        <Research />
        <UserNeeds />
        <Features />
        <CompetitorAnalysis />
        <UniqueFeatures /> */}
        {/* <UserPersona />
        <TaskMapping />
        <Sketches />
        <Screens /> */}
    </div>
  )
}

export default Surbing