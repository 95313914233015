import React from 'react';
import './Styles/Case.scss';
import { NavLink } from "react-router-dom";
import ProxyLogo from './Styles/proxy.png';
import SafeguardLogo from './Styles/safeguard.png';
import ParcelLogo from './Styles/parcel.png';
import ThreetryLogo from './Styles/threetry.png';
import SurbingLogo from './Styles/surbing.png';


// import Surbing from './Surbing';

const Case = () => {
  const boxes = ["box1", "box2", "box3"];

  return (
    <div className="Case-container">
      <NavLink to="/proxy" className="card">
        <img src={ProxyLogo} className="logo" alt="" />
        <div className='img-container'>
        </div>
      </NavLink>
      <NavLink to="/safeguard" className="card">
        <img src={SafeguardLogo} className="logo" alt="" />
        <div className='img-container'>
        </div>
      </NavLink>
      <NavLink to="/parcel" className="card">
        <img src={ParcelLogo} className="logo" alt="" />
        <div className='img-container'>
        </div>
      </NavLink>
      {/* <NavLink to="/surbing" className="card">
        <img src={SurbingLogo} className="logo" alt="" />
        <div className='img-container'>
        </div>
      </NavLink> */}
    </div>
  );
};

export default Case;